import ContentfulImageView from "../contentful/ContentfulImageView";
import { 
    HStack, 
    Text, 
    Spacer,
    VStack,
    Box,
    useMediaQuery
} from "@chakra-ui/react";





export default function TripHeader({ trip }) {
    var [isMobile] = useMediaQuery('(max-width: 48em)');
    console.log('isMobile:', isMobile);

    return (
        <VStack width="100%" alignItems="center" spacing={1}>
            <Spacer height={8}/>
            <HStack width="100%" justify="space-between" alignItems="start">
                <Text fontSize="24px" align="start" fontWeight="bold">{trip.name}</Text>
                <Spacer />
            </HStack>
            <Box width="100%">
                <ContentfulImageView 
                    assetId={trip.image_id} 
                    height="400px"
                    width={isMobile ? "343px" : "640px"}
                />
            </Box>
            <HStack width="100%" justify="space-between">
                <VStack width="50%" alignItems="start">
                    <Text fontSize="16px" fontWeight="bold">Arrival</Text>
                    <Text fontSize="16px">{trip.start_date}</Text>
                </VStack>
                <Spacer />
                <VStack width="50%" alignItems="end">
                    <Text fontSize="16px" fontWeight="bold">Departure</Text>
                    <Text fontSize="16px">{trip.end_date}</Text>
                </VStack>
            </HStack>
        </VStack>
    )
}
