import React, { useState, useCallback } from 'react';
import { Box, VStack, Text, Tabs, TabList, TabPanels, Tab, TabPanel, useTab } from '@chakra-ui/react';
import ActivityCell from './ActivityCell';
import DirectionsCell from './DirectionsCell';

const CustomTab = React.forwardRef((props, ref) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];

  return (
    <Tab ref={ref} {...tabProps}>
      <Box
        py={2}
        px={4}
        borderRadius="8px"
        bg={isSelected ? "#5F22D9" : "gray.100"}
        color={isSelected ? "white" : "black"}
        width="96px"
        height="80px"
      >
        {props.children}
      </Box>
    </Tab>
  );
});

const ItineraryView = ({ itinerary, onDelayedTabChange }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleTabChange = useCallback((index) => {
    setSelectedIndex(index);
    setTimeout(() => {
      onDelayedTabChange(index);
    }, 2000);
  }, [onDelayedTabChange]);

  return (
    <Tabs index={selectedIndex} onChange={handleTabChange} variant="unstyled" width="100%">
      <TabList overflowX="auto" whiteSpace="nowrap" py={2} >
        {itinerary.details.map((day, index) => {
          const [first, second, third] = day.date.split(' ');
          return (
            <CustomTab key={index} flexShrink={0} height="80px" width="75px">
              <VStack spacing={0} align="center">
                <Text fontSize="xs">{first}</Text>
                <Text fontWeight="bold" fontSize="md">{second}</Text>
                <Text fontSize="xs">{third}</Text>
              </VStack>
            </CustomTab>
          );
        })}
      </TabList>
      <TabPanels>
        {itinerary.details.map((day, index) => (
          <TabPanel key={index} p={0}>
            <VStack mt={4} spacing={4} align="stretch">
              {day.details.map((detail, idx) => {
                if (detail.item_type === "directions") {
                  return <DirectionsCell key={idx} detail={detail} />
                } else {
                  return <ActivityCell key={idx} detail={detail} />
                }
              })}
            </VStack>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default ItineraryView;