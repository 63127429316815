import { HStack, Text, Image } from "@chakra-ui/react";
import driving from "../Assets/car_icon.png";
import walking from "../Assets/walking_icon.png";
import biking from "../Assets/cycling_icon.png";
import transit from "../Assets/transit_icon.png";

export default function DirectionsCell({ detail }) {

    const modeIcon = {
        "driving": driving,
        "walking": walking,
        "biking": biking,
        "transit": transit
    }

    return (
        <HStack width="100%" justify="space-between" backgroundColor="gray.100" borderRadius="8px" padding="8px">
            <Image src={modeIcon[detail.mode]} width="24px" height="24px" />
            <Text>{detail.duration_text}</Text>
        </HStack>
    )
}