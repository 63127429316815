import React, { useState, useRef } from 'react';
import {
  Box, Button, Image, Text, VStack, HStack, Heading,
  FormControl, FormLabel, Avatar, Modal,
  ModalOverlay, ModalContent, ModalHeader,
  ModalBody, ModalCloseButton, ModalFooter, useDisclosure
} from '@chakra-ui/react';
import AddPhoto from './Assets/add_photo.png'
import { useMediaQuery } from '@chakra-ui/react';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { SimpleBorderButton } from './CustomComponents/SimpleBorderButton';
import { fire_tracking_event } from '../firebase/firebase';

function ProfilePhotoSection({ user, auth, app, selectedImage, onImageSelected, onRemoveImage }) {
  
  const [uploading, setUploading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const inputRef = useRef(null);

  const handleChangeClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const previewURL = URL.createObjectURL(file);
    onImageSelected(file);// Pass the URL back to parent
    fire_tracking_event("referral.profile.photo.uploaded")
  };

  const handleRemove = () => {
    onRemoveImage();
  };
    
  return (
    <VStack spacing={2} align="flex-start" height="96px" width="100%">
      <HStack align="center"  width="100%" justify="space-between" height="96px">
        {/* Display either the chosen image or a default Avatar if none */}
        <VStack align="flex-start" height="100%">
          <Text fontSize="16px" fontWeight="bold" color="black" >Profile Photo</Text>
          <Text fontSize="14px" color="black">Recommended 300 x 300px</Text>
          <HStack height="32px">
            <SimpleBorderButton onClick={handleChangeClick}>
              {selectedImage ? "Change" : "Add"}
            </SimpleBorderButton>
            {!selectedImage && (
              <Text fontSize="14px" color="black">(optional)</Text>
            )}
            {selectedImage && (
              <SimpleBorderButton onClick={handleRemove}>
                Remove
              </SimpleBorderButton>
            )}
          </HStack>
        </VStack>
        {selectedImage ? (
          <Image
            boxSize="96px"
            borderRadius="full"
            src={selectedImage}
            alt="Profile Image"
            objectFit="cover"
          />
        ) : (
          <Image
            boxSize="96px"
            borderRadius="full"
            src={AddPhoto}
            alt="Profile Image"
            objectFit="cover"
          />
        )}
      </HStack>
      {/* Hidden file input */}
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </VStack>
  );
}

export default ProfilePhotoSection;