import React from 'react';
import { useState, useEffect } from 'react';
import {
    Button,Text,
    VStack, Modal,
    ModalOverlay, ModalContent,
    ModalFooter,
    ModalBody,
    Box, HStack, Spacer,
    Progress, useMediaQuery
} from '@chakra-ui/react';
import ItineraryView from './ItineraryView';
import TripHeader from './TripHeader';
import SignUpModal from '../SignUpModal';
import DownloadAppModal from '../DownloadAppModal';
import { auth, app, fire_tracking_event } from '../../firebase/firebase';
import MapView from '../MapView';



function TripView({code, tripType, isOpen, onClose}) {
    const [state, setState] = useState("loading");
    const [trip, setTrip] = useState({});
    const [welcome_message, setWelcomeMessage] = useState(null);
    var [isMobile] = useMediaQuery('(max-width: 48em)');
    console.log("isMobile:", isMobile)

    const onAction = () => {
        setState("auth")
    }
    useEffect(() => {
        switch (state) {
            case "loading":
                fetch(`https://www.vaicai.com/api/social/web/trip/${code}/`)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    setTrip(data.trip);
                    setWelcomeMessage(data.welcome_message);
                    setState("itinerary");
                })
                .catch(error => console.error('Error fetching data:', error));
                break;
            case "itinerary":
                break;
            case "download":
                break;
            default:
                break;
        }
    }, [state, code]);

    if (state === "auth") {
        return (
            <div>
                <div class="justify-center flex" id="recaptcha-container"></div>
                    <SignUpModal auth={auth} app={app} isOpen={isOpen} onClose={onClose} welcomeMessage={welcome_message} onComplete={() => {
                        fire_tracking_event(`trip_share.signup_complete.${code}`)
                    setState("download");
                }} />
            </div>
        )
    } else if (state === "download") {
        return <DownloadAppModal isOpen={isOpen} onClose={onClose} message="Download the app to finish planning your trip!" referralCode={code} />
    } else {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
            <ModalContent 
                width={isMobile ? "472px" : "672px"}
                maxWidth={isMobile ? "472px" : "672px"}
                minWidth={isMobile ? "472px" : "672px"}
                mx="auto"
                overflow="hidden"
            >
                <ModalBody p={4}>
                    {state === "loading" && <Progress size='xs' isIndeterminate />}
                    {state === "itinerary" && trip.itinerary &&
                        <VStack width="100%" spacing={4}>
                            <Box width="100%">
                                <TripHeader trip={trip} />
                            </Box>
                            {/* grey line */}
                            <Box width="100%" height={0.5} bg="gray.200" />
                            <HStack width="100%" justify="space-between">
                                <Text fontSize="20px" fontWeight="bold">Location</Text>
                                <Spacer />
                            </HStack>
                            <MapView latitude={trip.latitude} longitude={trip.longitude} width={640} height={320} borderRadius={8} />
                            <Box width="100%">
                                <ItineraryView itinerary={trip.itinerary} onDelayedTabChange={onAction}/>
                            </Box>
                        </VStack>
                    }

                    {state === "download" && <DownloadAppModal message="Download the app to see your full itinerary!" />}
                </ModalBody>
                <ModalFooter>
                    <Button
                        p={4}
                        variant="solid"
                        bg="#5F22D9"
                        color="white"
                        size="lg"
                        width="full"
                        onClick={onAction}
                    >
                        Join Trip
                    </Button>
                </ModalFooter>
            </ModalContent>
            
            </Modal>
        )
    }
}

export default TripView;