
import logo from './Assets/logo.svg';
import backgroundImage from './Assets/bg.svg';
import instagram from './Assets/Icons/Instagram.svg';
import facebook from './Assets/Icons/Facebook.svg';
import tikTok from './Assets/Icons/TikTok.svg';
import twitter from './Assets/Icons/TwitterX.svg';
import React from 'react';
import SupportModal from  './SupportModal'
import appStoreButton from './Assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
// import bezel from './Assets/Bezel.svg'
import headerDivider from './Assets/HeaderDivider.png'
import { useParams } from "react-router-dom";
import headerScreens from './Assets/headerScreens.svg'
import sharingLogo from './Assets/SharingSectionLogo.png'
import StepCard from './CustomComponents/StepCard';
import SendCode from './Assets/SendCode.png'
import SignUp from './Assets/SignUp.png'
import SaveAndExplore from './Assets/SaveExplore.png'
import KaifaLogo from './Assets/Kaifa.png'
import ThousandsOfDestinations from './Assets/1000s.png'
import WeekendLogo from './Assets/Weekend.png'
import SpendAndEarn from './Assets/SpendAndEarn.png'
import Seamless from './Assets/Seamless.png'
import Rewards from './Assets/Rewards.png'
import Tailored from './Assets/Tailored.png'
import Hotels from './Assets/Hotels.png'
import HeaderBackground from './Assets/Website_Header_Image-Purple_Overlay.jpg'
import { useMediaQuery, Menu, MenuButton, MenuItem, MenuList, MenuDivider, IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import TripView from './TripView/TripView';
import constants from '../constants';

import {
  Box,
  Button,Container,
  Heading,Text,
  VStack,HStack,
  Image,Flex, Link, Icon, Spacer
} from '@chakra-ui/react';
import ReferralModal from './ReferralModal';
import { Spa } from 'grommet-icons';


function Header({onSupportClick}) {
  var [isMobile] = useMediaQuery('(max-width: 48em)');
  if (isMobile) {
    return (
        <Flex align="center" justifyContent="space-between" p="4" width="100%">
          <Spacer/>
          <Image src={logo} width="auto"/>
          <Spacer/>
        <Menu>
        <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="unstyled"
              color="white"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            />
                
                <MenuList>
                  <MenuItem>Profile</MenuItem>
                  <MenuItem>Settings</MenuItem>
                  <MenuItem>Logout</MenuItem>
                  <MenuDivider />
                  <MenuItem>Help</MenuItem>
                </MenuList>
              </Menu>
        </Flex>
          );
    } else {
    return (
      <Flex align="center" justifyContent="space-between" p="24" width="100%">
        {/* <Spacer width={32}/> */}
        <Image src={logo} width="auto"/>
        <Spacer/>
        <HStack spacing="24px" width="auto">
          <a href="#referrals">
            <Button variant="link" color={'white'}>Referrals</Button>
          </a>
          <Image src={headerDivider} width="auto"/>
            <a href="#how-does-it-work">
            <Button variant="link" href="#how-does-it-work" color={'white'}>How Does It Work?</Button>
            </a>
            <Image src={headerDivider} width="auto"/>
            <a href="https://blog.vaicai.com" style={{ textDecoration: 'none' }}>
              <Button variant="link"  color={'white'}>Blog</Button>
            </a>
            <Image src={headerDivider} width="auto"/>
            <Button variant="link" color={'white'} onClick={onSupportClick}>Support</Button>
            <Image src={headerDivider} width="auto"/>
            <a href="https://www.instagram.com/vaicaiapp">
              <img src={instagram} alt="Instagram" className="instagram"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61553078432580">
              <img src={facebook} alt="Facebook" className="facebook"/>
            </a>
            <a href="https://www.tiktok.com/@vaicaiapp">
              <img src={tikTok} alt="Tik Tok" className="Tik Tok"/>
            </a>
            <a href="https://x.com/vaic_ai">
              <img src={twitter} alt="Twitter" className="Twitter"/>
            </a>
        </HStack>
      </Flex>
    );
  }
}

function HeaderContent() {
    let [isMobile] = useMediaQuery('(max-width: 48em)');
    if (isMobile) {
      return (
        <VStack width="100%">
          <Spacer width={4}/>
          <VStack spacing={8} maxWidth="md">
            <Heading fontWeight={"bold"} fontSize={40} color={'white'}>
              YOUR ULTIMATE TRAVEL COMPANION!
            </Heading>
            <Text  fontSize={16} color="white">
            VaicAI harnesses the transformative power of AI to curate tailor-made holiday experiences around the globe, all based on your unique preferences and interests. Whether you crave a cultural immersion, a thrilling adventure, or a serene getaway, VaicAI has you covered.
            </Text>
            <a href="https://apps.apple.com/us/app/vaicai/id6443838892">
              <img src={appStoreButton} alt="App Store" className="appstore"/>
            </a>
          </VStack>
          <Spacer/>
          <img src={headerScreens}/>
          <Spacer width={4}/>
        </VStack>
      )
      } else {
        return (
          <HStack width="100%" p={24}>
            {/* <Spacer width={16}/> */}
            <VStack spacing={8} maxWidth="md">
              <Heading fontWeight={"bold"} fontSize={40} color={'white'} textAlign="left">
                YOUR ULTIMATE TRAVEL COMPANION!
              </Heading>
              <Text  fontSize={16} color="white" textAlign="left">
              VaicAI harnesses the transformative power of AI to curate tailor-made holiday experiences around the globe, all based on your unique preferences and interests. Whether you crave a cultural immersion, a thrilling adventure, or a serene getaway, VaicAI has you covered.
              </Text>
              <a href="https://apps.apple.com/us/app/vaicai/id6443838892">
                <img src={appStoreButton} alt="App Store" className="appstore"/>
              </a>
            </VStack>
            <Spacer/>
            <img src={headerScreens}/>
            {/* <Spacer width={4}/> */}
          </HStack>
        );
      }
}

function SharingCards() {
  var [isMobile] = useMediaQuery('(max-width: 48em)');
  console.log(isMobile)
  if (isMobile) {
    return (
      <VStack>
        <StepCard image={SendCode} title="Step 1:" subtitle="Send Code" body="First, share your unique code with your friends and family using the links below!"/>
        <StepCard image={SignUp} title="Step 2:" subtitle="Sign up to VaicAI" body="Next, your friends and family will sign up using your unique code."/>
        <StepCard image={SaveAndExplore} title="Step 3:" subtitle="Save & Explore" body="Finally, you and your friends will earn VaicAI coins, getting you closer to your travel rewards!"/>
      </VStack>
    );
  } else {
    return (
        <HStack spacing={8}>
          <StepCard image={SendCode} title="Step 1:" subtitle="Send Code" body="First, share your unique code with your friends and family using the links below!"/>
          <StepCard image={SignUp} title="Step 2:" subtitle="Sign up to VaicAI" body="Next, your friends and family will sign up using your unique code."/>
          <StepCard image={SaveAndExplore} title="Step 3:" subtitle="Save & Explore" body="Finally, you and your friends will earn VaicAI coins, getting you closer to your travel rewards!"/>
        </HStack>
      );
  }
}


function WhereWillYouGo (){
    let [isMobile] = useMediaQuery('(max-width: 48em)');
    if (isMobile) {
      <HStack backgroundColor={'white'} width='100%'>
        <Spacer/>
        <VStack spacing={8} maxWidth="md" padding="20px" backgroundColor={'white'}>
          <Heading fontWeight={"bold"} fontSize={40} color={'black'}>
            Where Will You Go?
          </Heading>
          <Text  fontSize={16} color="black">
          Whether you crave a cultural immersion, a thrilling adventure, or a serene getaway, VaicAI has you covered.
          </Text>
          <VStack>
            <VStack width={275} height={72}>
              <Image src={KaifaLogo} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                Your personal Travel Assistant
              </Text>
              <Text  fontSize={16} color="black">
              Kaifa is eagerly standing by, ready to assist you in booking the journey of a lifetime!
              </Text>
            </VStack>
            <VStack width={275} height={72}>
              <Image src={ThousandsOfDestinations} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                1000's of Destinations
              </Text>
              <Text  fontSize={16} color="black">
                Craft your perfect journey from the ground up or select from our curated list of exciting trips.
              </Text>
            </VStack>
            <VStack width={275} height={72}>
              <Image src={SpendAndEarn} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                Spend and Earn
              </Text>
              <Text  fontSize={16} color="black">
                Get ready to indulge in adventures while enjoying the perks of earning rewards along the way.
              </Text>
            </VStack>
            <VStack width={275} height={72}>
              <Image src={WeekendLogo} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                Weekend adventures
              </Text>
              <Text  fontSize={16} color="black">
              Pre-set your preferences now to receive inspiring ideas for spontaneous getaways! Let's make every weekend an adventure!
              </Text>
            </VStack>
          </VStack>
          </VStack>
        <Spacer/>
      </HStack>
    } else {
    return (
      <HStack backgroundColor={'white'} width='100%'>
        <Spacer/>
        <VStack spacing={8} maxWidth="md" padding="20px" backgroundColor={'white'}>
          <Heading fontWeight={"bold"} fontSize={40} color={'black'}>
            Where Will You Go?
          </Heading>
          <Text  fontSize={16} color="black">
          Whether you crave a cultural immersion, a thrilling adventure, or a serene getaway, VaicAI has you covered.
          </Text>
          <HStack>
            <VStack width={275} height={72}>
              <Image src={KaifaLogo} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                Your personal Travel Assistant
              </Text>
              <Text  fontSize={16} color="black">
              Kaifa is eagerly standing by, ready to assist you in booking the journey of a lifetime!
              </Text>
            </VStack>
            <VStack width={275} height={72}>
              <Image src={ThousandsOfDestinations} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                1000's of Destinations
              </Text>
              <Text  fontSize={16} color="black">
                Craft your perfect journey from the ground up or select from our curated list of exciting trips.
              </Text>
            </VStack>
            <VStack width={275} height={72}>
              <Image src={SpendAndEarn} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                Spend and Earn
              </Text>
              <Text  fontSize={16} color="black">
                Get ready to indulge in adventures while enjoying the perks of earning rewards along the way.
              </Text>
            </VStack>
            <VStack width={275} height={72}>
              <Image src={WeekendLogo} width="auto"/>
              <Text fontWeight={"bold"} fontSize={16} color="black">
                Weekend adventures
              </Text>
              <Text  fontSize={16} color="black">
              Pre-set your preferences now to receive inspiring ideas for spontaneous getaways! Let's make every weekend an adventure!
              </Text>
            </VStack>
          </HStack>
          </VStack>
        <Spacer/>
      </HStack>
    );
    }
}

function SharingSection() {

    return (
      <HStack backgroundColor={'white'}>
        <Spacer/>
        <VStack spacing={8} maxWidth="md" padding="20px" backgroundColor={'white'}>
        
            <Image src={sharingLogo} width="532" height="48"/>
            <Heading fontWeight={"bold"} fontSize={40} color={'black'}>
              Sharing is Earning!
            </Heading>
            <Text  fontSize={16} color="black">
            Share the travel bug with your friends and family to continue earning VaicAI coins, getting you closer to your travel rewards!
            </Text>
            <SharingCards/>
        </VStack>
        <Spacer/>
      </HStack>
      );
}

function CurvedSection() {
  return (
    <Box
      mt="-20px" // Adjust this value to overlap the previous section
      pt="20px" // Push content down inside the container
      bg="white"
      borderRadius="20px 20px 0 0" // Applies a border-radius to the top left and top right
      width="100%"
    >
      <SharingSection/>
    </Box>
  );
}

function HowDoesItWork() {
  let [isMobile] = useMediaQuery('(max-width: 48em)');
  if (isMobile) {
    return (
      <VStack backgroundColor='white'>
      <Heading fontWeight={"bold"} fontSize={40} color={'black'}>
        How Does It Work?
      </Heading>
      <Spacer height={48} backgroundColor='white'/>
      <VStack>
      <Image src={Seamless} width="auto"/>
        <VStack >
        <Heading fontWeight={"bold"} fontSize={24} color="black">
        Seamlessly plan trips with AI Magic!
        </Heading>
        <Text  fontSize={16} color="black">
        Experience the future of travel planning with AI Magic! Effortlessly create personalized trips that cater to your preferences and style with the help of advanced AI technology. Say goodbye to hours of research and hello to a tailor-made adventure!
        </Text>
        
        </VStack>
      </VStack>
      <VStack>
      <VStack >
      <Image src={Tailored} width="auto"/>
        <Heading fontWeight={"bold"} fontSize={24} color="black">
        TAILORED FLIGHTS FOR ULTIMATE COMFORT!
        </Heading>
        <Text  fontSize={16} color="black">
          Experience travel like never before with tailored flights for ultimate comfort! Customize your journey from start to finish with premium amenities and personalized services, ensuring a relaxing and luxurious flight every time.       
        </Text>
        </VStack>
        
      </VStack>
      <VStack>
        <Image src={Hotels} width="auto"/>
        <VStack >
        <Heading fontWeight={"bold"} fontSize={24} color="black">
        RECOMMENDED HOTELS, PREMIUM & AFFORDABLE!
        </Heading>
        <Text  fontSize={16} color="black">
        Discover a curated selection of hotels offering both premium and affordable options for every type of traveler. Enjoy high-quality amenities, excellent service, and comfort, whether you're on a budget or seeking luxury.
        </Text>
        </VStack>
      </VStack>
      <VStack>
        <VStack >
        <Image src={Rewards} width="auto"/>
        <Heading fontWeight={"bold"} fontSize={24} color="black">
          EARN EXCLUSIVE REWARDS ALONG THE WAY!
        </Heading>
        <Text  fontSize={16} color="black">
          Discover a world of perks and benefits with each step you take. Earn exclusive rewards on your journey and enjoy a personalized experience tailored to your preferences! 
        </Text>
        </VStack>
        
      </VStack>
    </VStack>
    );
  } else {
  return (
    <VStack backgroundColor='white'>
      <Heading fontWeight={"bold"} fontSize={40} color={'black'}>
        How Does It Work?
      </Heading>
      <Spacer height={48} backgroundColor='white'/>
      <HStack>
        <Image src={Seamless} width="auto"/>
        <VStack width={494}>
        <Heading fontWeight={"bold"} fontSize={32} color="black">
        Seamlessly plan trips with AI Magic!
        </Heading>
        <Text  fontSize={16} color="black">
        Experience the future of travel planning with AI Magic! Effortlessly create personalized trips that cater to your preferences and style with the help of advanced AI technology. Say goodbye to hours of research and hello to a tailor-made adventure!
        </Text>
        </VStack>
      </HStack>
      <HStack>
      <VStack width={494}>
        <Heading fontWeight={"bold"} fontSize={32} color="black">
        TAILORED FLIGHTS FOR ULTIMATE COMFORT!
        </Heading>
        <Text  fontSize={16} color="black">
          Experience travel like never before with tailored flights for ultimate comfort! Customize your journey from start to finish with premium amenities and personalized services, ensuring a relaxing and luxurious flight every time.       
        </Text>
        </VStack>
        <Image src={Tailored} width="auto"/>
      </HStack>
      <HStack>
        <Image src={Hotels} width="auto"/>
        <VStack width={494}>
        <Heading fontWeight={"bold"} fontSize={32} color="black">
        RECOMMENDED HOTELS, PREMIUM & AFFORDABLE!
        </Heading>
        <Text  fontSize={16} color="black">
        Discover a curated selection of hotels offering both premium and affordable options for every type of traveler. Enjoy high-quality amenities, excellent service, and comfort, whether you're on a budget or seeking luxury.
        </Text>
        </VStack>
      </HStack>
      <HStack>
        <VStack width={494}>
        <Heading fontWeight={"bold"} fontSize={32} color="black">
          EARN EXCLUSIVE REWARDS ALONG THE WAY!
        </Heading>
        <Text  fontSize={16} color="black">
          Discover a world of perks and benefits with each step you take. Earn exclusive rewards on your journey and enjoy a personalized experience tailored to your preferences! 
        </Text>
        </VStack>
        <Image src={Rewards} width="auto"/>
      </HStack>
    </VStack>
  );
  }
}

function DownloadToday() {
  return (
    <VStack backgroundColor='white' width='100%'>
    <Spacer/>
    <VStack backgroundColor='white' height={222} spacing={8}>
      <Heading fontWeight={"1000"} fontSize={32} color={'black'}>
        Download Today!
      </Heading>
      <Text  fontSize={16} color="black">
        Discover a world of adventure with our innovative app that tailors trips just for you. Available now on the App Store. Start planning your dream trip today—download VaicAI and let your journey begin!
      </Text>
      <a href="https://apps.apple.com/us/app/vaicai/id6443838892">
        <img src={appStoreButton} alt="App Store" className="appstore"/>
      </a>
    </VStack>
    <Spacer/>
    </VStack>
  );
}

function Footer({onSupportClick}) {
  let [isMobile] = useMediaQuery('(max-width: 48em)');
  if (isMobile) {
    return (
      <VStack bg="#5F22D9" width='100%'>
      <Spacer height={64}/>
      <VStack width='100%'>
        <Spacer width={4}/>
        <VStack >
          <Image src={logo} width="auto"/>
          <Text color='white'>With VaicAI, every journey becomes an unforgettable adventure. Start exploring today and unlock a world of endless possibilities! Discover Your Next Adventure with VaicAI: Your Ultimate Travel Companion</Text>
          <Spacer height={16}/>
        </VStack>
        <Spacer/>
        <VStack>
          {/* <Spacer/> */}
          <Heading color='white' fontSize={16}>Navigation:</Heading>
          <a href="#about">
            <Button variant="link" href="#about" color={'white'} fontSize={16} fontWeight={'normal'}>About</Button>
          </a>
          <a href="#referrals">
            <Button variant="link" href="#referrals" color={'white'} fontSize={16} fontWeight={'normal'}>Referrals</Button>
          </a>
          <a href="https://blog.vaicai.com">
          <Button variant="link" href="#" color={'white'} fontSize={16} fontWeight={'normal'}>Blog</Button>
          </a>
          <Button variant="link" color={'white'} fontSize={16} fontWeight={'normal'} onClick={onSupportClick}>Support</Button>
        </VStack>
        <Spacer/>
        <VStack>
          <Text color='white' fontSize={16}>FOLLOW US:</Text>
          <HStack>
            <a href="https://www.instagram.com/vaicaiapp">
              <img src={instagram} alt="Instagram" className="instagram"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61553078432580">
              <img src={facebook} alt="Facebook" className="facebook"/>
            </a>
            <a href="https://www.tiktok.com/@vaicaiapp">
              <img src={tikTok} alt="Tik Tok" className="Tik Tok"/>
            </a>
            <a href="https://x.com/vaic_ai">
              <img src={twitter} alt="Twitter" className="Twitter"/>
            </a>
          </HStack>
        </VStack>
        <Spacer width={4}/>
      </VStack>
      <Spacer height={48}/>
      <HStack width={'100%'}>
        <Spacer width={2}/>
        <Text color='white'>© 2021 VaicAI. All rights reserved.</Text>
        <Spacer/>
      </HStack>
      <Spacer height={128}/>
    </VStack>
    );
  } else {
  return (
    <VStack bg="#5F22D9" width='100%' height={350}>
      <Spacer height={64}/>
      <HStack width='100%'>
        <Spacer width={4}/>
        <VStack width={409} >
          <Image src={logo} width="auto"/>
          <Text color='white'>With VaicAI, every journey becomes an unforgettable adventure. Start exploring today and unlock a world of endless possibilities! Discover Your Next Adventure with VaicAI: Your Ultimate Travel Companion</Text>
          <Spacer height={16}/>
        </VStack>
        <Spacer/>
        <VStack>
          {/* <Spacer/> */}
          <Heading color='white' fontSize={16}>Navigation:</Heading>
          <a href="#about">
            <Button variant="link" href="#about" color={'white'} fontSize={16} fontWeight={'normal'}>About</Button>
          </a>
          <a href="#referrals">
            <Button variant="link" href="#referrals" color={'white'} fontSize={16} fontWeight={'normal'}>Referrals</Button>
          </a>
          <a href="https://blog.vaicai.com">
          <Button variant="link" href="#" color={'white'} fontSize={16} fontWeight={'normal'}>Blog</Button>
          </a>
          <Button variant="link" color={'white'} fontSize={16} fontWeight={'normal'} onClick={onSupportClick}>Support</Button>
        </VStack>
        <Spacer/>
        <VStack>
          <Text color='white' fontSize={16}>FOLLOW US:</Text>
          <HStack>
            <a href="https://www.instagram.com/vaicaiapp">
              <img src={instagram} alt="Instagram" className="instagram"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61553078432580">
              <img src={facebook} alt="Facebook" className="facebook"/>
            </a>
            <a href="https://www.tiktok.com/@vaicaiapp">
              <img src={tikTok} alt="Tik Tok" className="Tik Tok"/>
            </a>
            <a href="https://x.com/vaic_ai">
              <img src={twitter} alt="Twitter" className="Twitter"/>
            </a>
          </HStack>
        </VStack>
        <Spacer width={4}/>
      </HStack>
      <Spacer height={48}/>
      <HStack width={'100%'}>
        <Spacer width={2}/>
        <Text color='white'>© 2024 VaicAI. All rights reserved.</Text>
        <Spacer/>
      </HStack>
      <Spacer height={128}/>
    </VStack>
  );
  }
}

class  LandingBase extends React.Component {

  constructor(props){
    super(props);
    console.log(`show trip view: ${props.showTripView}`)
    if (props.landingPath === 'shared') {
      console.log("show trip view")
      this.state = {
        isTripViewOpen: true,
        isReferralModalOpen: false,
        isSupportModalOpen: false,
        email: '',
        landingPath: props.landingPath,
        code: props.code,
      }
    } else if (props.landingPath === 'referral') {
      console.log("show referral")
        this.state = {
          isReferralModalOpen: true,
          code: props.code,
          isSupportModalOpen: false,
          email: '',
          landingPath: props.landingPath,
          isTripViewOpen: false,
        }
      } else {
        this.state = {
          isSupportModalOpen: false,
          isReferralModalOpen: false,
          isTripViewOpen: false,
          email: '',
          landingPath: props.landingPath
        }
      }
    this.onCloseSupportModal = this.onCloseSupportModal.bind(this);
    this.onCloseReferralModal = this.onCloseReferralModal.bind(this);
    this.onSupportClick = this.onSupportClick.bind(this);
    this.onTripViewClose = this.onTripViewClose.bind(this);
  }

  // handleSubscribe() {
  //   console.log(email);
  //   fetch("https://vaicai.com/api/marketing/subscribe/", {
  //       method: "POST",
  //       body: JSON.stringify({
  //           email: email,
  //       }),
  //       headers: {
  //           "Content-type": "application/json; charset=UTF-8"
  //       }
  //   })
  //   .then(response => {
  //       toast({
  //         title: "Email subscribed!",
  //         description: "Thank you for subscribing! Stay tuned for further news",
  //         status: "success",
  //         duration: 5000,
  //         isClosable: true,
  //     });
  //     setEmail("");
  //   })
  //   .catch(error => {
  //       console.error("There was an error!", error);
  //       toast({
  //         title: "🤔",
  //         description: "It seems like you're trying to subscribe to a registered email",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //     });
  //   });
  // }

  componentDidUpdate(prevProps) {
    if (this.props.landingPath !== prevProps.landingPath) {
      if (this.props.landingPath === 'referral') {
        this.setState({isReferralModalOpen: true})
      } else if (this.props.landingPath === 'shared') {
        this.setState({isTripViewOpen: true})
      }
    }
  }

  componentDidMount() {
    console.log(this.props.landingPath)
    if (this.props.landingPath === 'referral') {
      this.setState({isReferralModalOpen: true})
    } else if (this.props.landingPath === 'shared') {
      this.setState({isTripViewOpen: true})
    }
  }
  onCloseSupportModal() {
    this.setState({isSupportModalOpen: false});
  }

  onCloseReferralModal() {
    console.log("closing modal")
    this.setState({isReferralModalOpen: false});
  }

  onSupportClick() {
    this.setState({isSupportModalOpen: true});
    console.log("support clicked")
   }

   onTripViewClose() {
    this.setState({isTripViewOpen: false});
   }

  render() {
    const tripType = this.props.landingPath === 'shared' ? constants.TripType.GROUP : constants.TripType.FEATURED;

    return (
      <Box
        height="150vh"
        position="relative"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        width='100%'
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `url(${HeaderBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      >
        {this.state.isSupportModalOpen && <SupportModal isOpen={this.state.isSupportModalOpen} onClose={this.onCloseSupportModal} />}
        {this.state.isReferralModalOpen && <ReferralModal referralCode={this.state.code} isOpen={this.state.isReferralModalOpen} onClose={this.onCloseReferralModal}/>}
        {this.state.isTripViewOpen && <TripView code={this.state.code} tripType={tripType} isOpen={this.state.isTripViewOpen} onClose={this.onTripViewClose}/>}
        <VStack width="100%">
            <Header onSupportClick={this.onSupportClick}/>
            <HeaderContent/>
          <Box spacing={0} width={'100%'}>
            <section id="referrals">
              <CurvedSection/>
            </section>
            <Spacer height={20} backgroundColor='white'/>
            <section id='about'>
              <WhereWillYouGo/>
            </section>
            <Spacer height={20} backgroundColor='white'/>
            <section id="how-does-it-work">
              <HowDoesItWork/>
            </section>
            <Spacer height={20} backgroundColor='white'/>
            <DownloadToday/>
            <Spacer height={20} backgroundColor='white'/>
            <Footer onSupportClick={this.onSupportClick}/>
          </Box>
        </VStack>
      </Box>
    );
  }
}

const Landing = () => {
  const { landingPath, code } = useParams();
  return <LandingBase landingPath={landingPath} code={code} />;
}

export default Landing;