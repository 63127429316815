import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Image,
  Text,
  VStack,
  Button,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import Background from './Assets/referral-modal-bg.jpg';
import logo from './Assets/logo.svg';

const WelcomeModal = ({ isOpen, onClose, referrerName, referralMessage, onSignUpClick, onLoginClick }) => {
  const [isMobile] = useMediaQuery('(max-width: 48em)');
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none">
      <ModalOverlay bg="blackAlpha.600" />
      <ModalContent
        borderRadius="lg"
        overflow="hidden"
        mx={4}
        my={4}
        width={["calc(100% - 32px)", "calc(100% - 32px)", "612px"]}
        maxWidth="612px"
        p={0} // remove default padding
      >
        <ModalCloseButton size="lg" color="white" zIndex="10" />
        {/* Image container: use full width and a fixed height */}
        <Box position="relative" width="100%" height={["472px","472px","496px"]}>
          <Image
            src={Background}
            alt="Background"
            width="100%"
            height="100%"
            objectFit="cover"
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            color="white"
            textAlign="center"
            // px={[2,4]}
            maxWidth="100%"
          >
            <Flex justifyContent="center" width="100%" mb={[8,8,20]}>
              <Image src={logo} alt="Company Logo" />
            </Flex>
            <Text fontSize={["24px","32px"]} fontWeight="bold">
              Welcome!
            </Text>
            {referralMessage && (
              <Text fontSize={["16px","20px"]} mt={2}>
                {referralMessage}
              </Text>
            )}
            {!referralMessage && (
              <Text fontSize={["16px","20px"]} mt={2}>
                You've been referred to VaicAI. When you sign up here you'll recieve Vacay coins that you can use towards your trips.
                Sign up to claim your travel bonus and start getting personalized travel recommendations!
              </Text>
            )}
          </Box>
        </Box>

        {/* Button section */}
        <VStack p={4} spacing={4} bgColor="white">
          <Button
            p={4}
            variant="solid"
            size="lg"
            width="full"
            bg="#5F22D9"
            color="white"
            onClick={onSignUpClick}
          >
            Sign Up
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeModal