import React, { useState, useEffect } from 'react';
import { Img as Image } from 'react-image';
import ContentfulImageLoader from './ContentfulImageLoader';
import { Progress } from '@chakra-ui/react';
const ContentfulImageView = ({ assetId, width, height, placeholder }) => {
  const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
  const [imageURL, setImageURL] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loader = new ContentfulImageLoader(spaceId, accessToken); // Assuming this is a valid class or function to load images
    loader
      .fetchImageURL(assetId)
      .then(url => {
        setImageURL(url);
        setIsLoading(false);
      })
      .catch(error => {
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  }, [spaceId, accessToken, assetId]);

  return (
    <div style={{ width: `${width}`, height: `${height}`, overflow: 'hidden' }}>
      {isLoading && <Progress size='xs' isIndeterminate />}
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      {imageURL && (
        <Image
          src={imageURL}
          loader={<div>{placeholder}</div>} // What to display while loading
          unloader={<div>Error loading image</div>} // What to display if there is an error
          style={{ width: '100%', height: '100%', objectFit: 'fill' }}
        />
      )}
    </div>
  );
};

export default ContentfulImageView;